<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/TZ_list' }">重要通知</el-breadcrumb-item>
      <el-breadcrumb-item>通知详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="img_list">
      <el-image class="xw_img" v-for="i in num" :key="'img' + i" :src="getImg(i)" lazy></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: "XW2",
  data() {
    return {
      num: 3,
    }
  },
  methods: {
    getImg(seq) {
      return require(`@/assets/xw2/${seq}.jpg`)
    }
  }
}
</script>

<style scoped>
.img_list {
  margin: 40px auto;
  width: 600px;
}

.xw_img {
  width: 100%;
  display: block;
  border-bottom: 1px solid #f5f5f5;
}
</style>